import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./common";

const store = configureStore({
  reducer: {
    common: commonReducer,
  },
  // middleware: (gDM) => gDM().concat(api.middleware),
});

export default store;
