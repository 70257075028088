import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { lazy, Suspense } from "react";

import "./App.scss";

const Landing = lazy(() => import("./pages/non-authenticated/landing/landing"));
const Terms = lazy(() => import("./pages/non-authenticated/terms/Terms"));
const Policy = lazy(() => import("./pages/non-authenticated/policy/Policy"));

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <Suspense fallback={<></>}>
        <Landing />
      </Suspense>
    ),
  },
  {
    path: "terms",
    element: (
      <Suspense fallback={<></>}>
        <Terms />
      </Suspense>
    ),
  },
  {
    path: "policy",
    element: (
      <Suspense fallback={<></>}>
        <Policy />
      </Suspense>
    ),
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
