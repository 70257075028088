import { createSlice } from "@reduxjs/toolkit";

const initialCommonState = {
  currentRoute: "",
};

const commonSlice = createSlice({
  name: "common",
  initialState: initialCommonState,
  reducers: {
    setCurrentRoute: (state, actions) => {
      state.currentRoute = actions.payload;
    },
  },
});

export const { setCurrentRoute } = commonSlice.actions;

export default commonSlice.reducer;
